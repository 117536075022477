import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import UmbracoImage from 'components/common/Image/UmbracoImage';

import './NotFoundPage.scss';

const NotFoundPage = ({ data }) => {
  const {
    pageNotFound,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  } = data;

  return (
    <Layout
      seo={{
        seoMetaTitle: pageNotFound?.seoMetaTitle,
        seoMetaKeywords: pageNotFound?.seoMetaKeywords,
        seoMetaDescription: pageNotFound?.seoMetaDescription,
        disableCanonicals: true,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
    >
      <section className="dt-not-found dt-container-wrapper">
        <div className="dt-not-found__holder">
          <Container fluid className="dt-not-found__content">
            <div className="dt-not-found__content-text">
              <strong className="dt-not-found__title">{pageNotFound?.title}</strong>
              <span className="dt-not-found__subtitle">{pageNotFound?.subtitleLine1Text}</span>
              <span className="dt-not-found__subtitle">{pageNotFound?.subtitleLine2Text}</span>
            </div>
            <Button variant="light" href={pageNotFound?.redirectButtonUrl?.[0].url}>
              {pageNotFound?.redirectButtonText}
            </Button>
          </Container>
        </div>
        <div className="dt-not-found__img-frame">
          <UmbracoImage
            className="dt-not-found__img"
            image={pageNotFound?.image}
            alt={pageNotFound?.imageAlt}
          />
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const queryDefaultProps = graphql`
  fragment FragmentNotFoundPageProps on PAGE_NOT_FOUND {
    title
    subtitleLine1Text
    subtitleLine2Text
    redirectButtonText
    seoMetaDescription
    seoMetaKeywords
    seoMetaTitle
    redirectButtonUrl {
      url
    }
    imageAlt
    image {
      fallbackUrl
      fluid {
        srcSet
        base64
      }
    }
  }
`;
